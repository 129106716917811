<template>
  <div id="report-container">
      <div
        id="x-spreadsheet-preview"
      ></div>
  </div>
</template>
<script>
import Spreadsheet from "@/plugins/x-data-spreadsheet";
import zhCN from "@/plugins/x-data-spreadsheet/src/locale/zh-cn";
import { getBiReport, execBiReport, exportBiReport } from "@/api/bi/bi_report"

export default {
  name: "report",
  data() {
    return {
    };
  },
  created() {
    document.title = "报表预览"
    Spreadsheet.locale("zh-cn", zhCN);
  },
  mounted() {
    const id = this.$route.query['_']
    getBiReport(id).then((response) => {
      if (response.code == 0) {
        document.title = response.data.name
      }
    });
    execBiReport(id, JSON.stringify(this.$route.query)).then(response => {
      console.log(response)
      if (response.code == 0) {
        this.init(response.data)
      }
    })
  },
  methods: {
    init(data) {
      const debug = this.$route.query['_debug'] == '1'
      const showToolbar = this.$route.query['_toolbar'] === undefined || this.$route.query['_toolbar'] == '1'
      this.xs = new Spreadsheet("#x-spreadsheet-preview", {
        mode: "read",
        showToolbar: showToolbar,
        showSaveButton: false,
        showBottomBar: false,
        showGrid: debug ? true : false,
        showPrintGrid: debug ? true: false,
        showContextMenu: false,
        showFixedHeader: debug ? true : false,
        selectable: debug ? true : false,
        view: {
          height: () => document.documentElement.clientHeight,
          width: () => document.documentElement.clientWidth,
        },
        emptyImage: "/images/img.png",
      }).on("toolbar_action", (action, value) => {
          if (action == 'export') {
            if (value == 'excel') {
              this.handleExportExcel()
            } else if (value == 'pdf') {
              this.handleExportPdf()
            }
          }
        });

      if (data && data.length > 0) {
        this.xs.loadData(data)
      }
    },
    handleExportExcel() {
      exportBiReport(
        this.$route.query["_"],
        JSON.stringify(this.$route.query),
        "xlsx"
      );
    },
    handleExportPdf() {
      exportBiReport(
        this.$route.query["_"],
        JSON.stringify(this.$route.query),
        "pdf"
      );
    },
  },
  watch: {
    $route(to, from) {
      if (to.path == from.path) {
        window.location.reload()
      }
    }
  }
};
</script>
